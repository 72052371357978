class Produkt {
  /** @param {{ produktId:String, tarifId:String, name:String, produkttyp:String, kundentyp:String }} data */
  constructor(data) {
    this.produktId = data.produktId
    this.tarifId = data.tarifId
    this.name = data.name
    this.produkttyp = data.produkttyp
    this.kundentyp = data.kundentyp
  }
}
export default Produkt
