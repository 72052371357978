import Vue from 'vue'
import VorversorgerService from '../../api/private/VorversorgerService'
import {Energiearten} from '../../constants'

const state = {
  vorversorger: []
}
const mutations = {
  setVorversorger (state, vorversorger) {
    vorversorger.sort((a, b) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
    Vue.set(state, 'vorversorger', vorversorger)
  }
}
const actions = {
  /**
   *
   * @param context
   */
  getVorversorger(context) {
    if (context.state.vorversorger.length === 0) {
      let stromVorversorger = VorversorgerService.getList(Energiearten.strom).then(response => {
        return response.data
      })
      let gasVorversorger = VorversorgerService.getList(Energiearten.gas).then(response => {
        return response.data
      })
      Promise.all([stromVorversorger, gasVorversorger])
        .then(vorversorgers => {
          let alleVorversorger = []
          vorversorgers.forEach(element => alleVorversorger.push(...element))
          return alleVorversorger
        })
        .then(alleVorversorger => {
          context.commit('setVorversorger', alleVorversorger)
        });
    }
  }
}
const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
