class Adresse {
  anrede
  vorname
  nachname
  strasse
  hausnummer
  hausnummerZusatz
  plz
  ort
  /**
   * @param {{ anrede:String, vorname:String, nachname:String, strasse:String, hausnummer:String,
   * hausnummerZusatz:?String, plz:String, ort:String }} data
   */
  constructor(data = null) {
    if (data !== null) {
      this.anrede = data.anrede
      this.vorname = data.vorname
      this.nachname = data.nachname
      this.strasse = data.strasse
      this.hausnummer = data.hausnummer
      this.hausnummerZusatz = data.hausnummerZusatz
      this.plz = data.plz
      this.ort = data.ort
    }
  }
}
export default Adresse
