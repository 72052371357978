import Vue from 'vue'
import ProduktRequest from '../../model/request/ProduktRequest'
import ProduktService from '../../api/private/ProduktService'

const state = {
  tarife: []
}
const mutations = {
  setTarife (state, tarife) {
    tarife.sort((a, b) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
    Vue.set(state, 'tarife', tarife)
  }
}
const actions = {
  /**
   *
   * @param context
   * @param ProduktRequest produktRequest
   * @returns {Promise<AxiosResponse<any>>}
   */
  getTarife(context, produktRequest) {
    let request = new ProduktRequest(produktRequest)
    return ProduktService.getList(request).then(response => {
      context.commit('setTarife', response.data)
      return response.data
    })
  },
}
const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
