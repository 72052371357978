import * as axios from 'axios'

export default function axios_setup(token) {
    token = token === null ? '' : token
    axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL ? process.env.VUE_APP_API_BASEURL : 'https://energie.fairtriebs-champions.de/api/v1'
    axios.defaults.timeout = 30 * 1000
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    axios.defaults.headers['Content-Type'] = 'application/json'
    // Add a response interceptor
    axios.interceptors.response.use(response => {
        return response
    }, error => {

        if (error.response.status === 401) {

            window.location.href = process.env.VUE_APP_FC_ADMIN_URL
        }

        return Promise.reject(error)
    })
}
