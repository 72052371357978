import './KundeRequest'
import '../domain/Produkt'

class AuftragRequest {
  /**
   * @param {{dateien: {sonstiges: [], auftrag: [], fairberaten: []}, lieferbeginn: string, zaehlernummer: string, widerrufsbelehrungZurKenntnis: boolean, produkttyp: string, produkt: {produktId: string, name: string, produkttyp: string, kundentyp: string}, wechselgrund: string, vorversorgerKuendigungsdatum: string, unterschriftsdatum: string, unterschriftsort: string, verbrauch: number, vorversorgerKundennummer: string, kundentyp: string, kuendiger: string, kunde: {rechnungsadresse: {ort: string, hausnummerZusatz: string, strasse: string, vorname: string, anrede: string, hausnummer: string, nachname: string, plz: string}, promotionErlaubtTelefonSms: boolean, promotionNewsletterErlaubt: boolean, marktlokation: null, faxnummer: {vorwahl: string, rufnummer: string, land: string}, promotionErlaubtEmail: boolean, handelsregisternummer: null, firmenname: null, registergericht: null, geburtsdatum: string, bankdaten: {unternehmen: string, kreditinstitut: string, iban: string, adresseKontoinhaber: {ort: string, hausnummerZusatz: string, strasse: string, vorname: string, anrede: string, hausnummer: string, nachname: string, plz: string}, kontoinhaber: string, bic: string}, branche: null, telefonnummer: {vorwahl: string, rufnummer: string, land: string}, lieferadresse: {ort: string, hausnummerZusatz: string, strasse: string, vorname: string, anrede: string, hausnummer: string, nachname: string, plz: string}, email: string}, vorversorger: string}} requestData
   * @param {{ produktId:String, tarifId:String, name:String, produkttyp:String, kundentyp:String }} produkt
   * @param {{ [] }} files
   */
  constructor(requestData, produkt, files) {
    this.auftragsnummer = requestData.auftragsnummer
    this.produkt = produkt
    this.kunde = requestData.kunde
    this.zaehlernummer = requestData.zaehlernummer
    this.verbrauch = requestData.verbrauch
    this.lieferbeginn = requestData.lieferbeginn
    this.wechselgrund = requestData.wechselgrund
    this.vorversorger = requestData.vorversorger
    this.vorversorgerKundennummer = requestData.vorversorgerKundennummer
    this.vorversorgerKuendigungsdatum = requestData.vorversorgerKuendigungsdatum
    this.kuendigung = requestData.kuendiger === 'Selbstgekündigt' ? 1 : 0
    this.unterschriftsdatum = requestData.unterschriftsdatum
    this.unterschriftsort = requestData.unterschriftsort
    this.dateien = files
    this.widerruf = requestData.widerrufsbelehrungZurKenntnis
  }
}

export default AuftragRequest
