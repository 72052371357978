<template>
  <div class="home">
    <b-row>
      <b-col>
        <b-card-group deck>
          <b-card
            title="Energie-Auftrag erfassen"
            img-src="/img/1-600x300.jpg"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
            v-if="$user !== null"
          >
            <b-card-text>
              Hier kannst du deine Energie-Aufträge in das Auftragssystem eingeben. Am schnellsten geht es, wenn du das
              Auftragsformular bereits eingescannt hast.
            </b-card-text>

            <template v-slot:footer>
              <b-dropdown split text="Jetzt Auftrag erfassen" :split-to="{ name: 'auftragsformular', params: { produkttyp: Energieart.Strom , kundentyp: Kundentyp.Privat}}" variant="primary" block>
                <b-dropdown-item :to="{ name: 'auftragsformular', params: { produkttyp: Energieart.Strom , kundentyp: Kundentyp.Privat}}">Strom Privat erfassen</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'auftragsformular', params: { produkttyp: Energieart.Strom , kundentyp: Kundentyp.Gewerbe}}">Strom Gewerbe erfassen</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'auftragsformular', params: { produkttyp: Energieart.Gas , kundentyp: Kundentyp.Privat}}">Gas Privat erfassen</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'auftragsformular', params: { produkttyp: Energieart.Gas , kundentyp: Kundentyp.Gewerbe}}">Gas Gewerbe erfassen</b-dropdown-item>
              </b-dropdown>
            </template>

          </b-card>
          <b-card
            title="Tarifrechner"
            img-src="/img/304-600x300.jpg"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
            style="max-width: 33%;"
          >
            <b-card-text>
              Siehe hier nach, welche Tarife & Preise du anbieten kannst.
            </b-card-text>

            <template v-slot:footer>
              <b-button to="/tarifrechner" variant="primary" block>Zum Tarifrechner</b-button>
            </template>

          </b-card>
          <b-card
            title="Dokumente"
            img-src="/img/24-600x300.jpg"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
            v-if="$user !== null"
          >
            <b-card-text>
              Lade hier Vorlagen, wie die Auftragsformulare runter.
            </b-card-text>

            <template v-slot:footer>
              <b-button to="/dokumente" variant="primary" block>Zu den Dokumenten</b-button>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import Vue from 'vue'
  import {ButtonPlugin, CardPlugin, DropdownPlugin} from 'bootstrap-vue'
  import {Energieart, Kundentyp} from '../../../common/constants'

  Vue.use(CardPlugin)
  Vue.use(ButtonPlugin)
  Vue.use(DropdownPlugin)

  export default {
    name: 'Home',
    data() {
      return {
        Energieart: Energieart,
        Kundentyp: Kundentyp
      }
    }
  }
</script>
