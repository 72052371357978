import AuftragRequest from './AuftragRequest'
import KundeRequest from './KundeRequest'
import PreisabfrageRequest from './PreisabfrageRequest'
import ProduktRequest from './ProduktRequest'

let Requests = {
  AuftragRequest,
  KundeRequest,
  PreisabfrageRequest,
  ProduktRequest
}
Object.values(Requests).forEach(v => {
  v.prototype.toQueryParameter = function () {
    return `?${Object.keys(this).map(key =>
      key + '=' + encodeURIComponent(this[key] instanceof Date ? this[key].toISOString() : this[key])).join('&')}`
  }
  v.prototype.toBodyParameter = function () {
    return JSON.stringify(this)
  }
})
export default Requests
