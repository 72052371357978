import Adresse from './Adresse'
class Bankdaten {
  kontoinhaber
  iban
  bic
  kreditinstitut
  adresseKontoinhaber
  /**
   * @param {{ kontoinhaber:String, iban:String, bic:String, kreditinstitut:String,
   * adresseKontoinhaber:?{ anrede:String, vorname:String, nachname:String, strasse:String, hausnummer:String,
   * hausnummerZusatz:?String, plz:String, ort:String } }} data
   */
  constructor(data= null) {
    if (data !== null) {
      this.kontoinhaber = data.kontoinhaber
      this.iban = data.iban
      this.bic = data.bic
      this.kreditinstitut = data.kreditinstitut
      this.adresseKontoinhaber = new Adresse(data.adresseKontoinhaber)
    }
  }
}
export default Bankdaten
