import Vue from 'vue'
import BrancheService from '../../api/private/BrancheService'

const state = {
  branchen: []
}
const mutations = {
  setBranchen (state, branchen) {
    branchen.sort((a, b) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
    Vue.set(state, 'branchen', branchen)
  }
}
const actions = {
  /**
   *
   * @param context
   * @returns {Promise<AxiosResponse<any>>}
   */
  getBranchen(context) {
    return BrancheService.getList().then(response => {
      context.commit('setBranchen', response.data)
      return response.data
    })
  },
}
const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
