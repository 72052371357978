import { setInteractionMode, ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/de.json';
import Vue from 'vue'

export default function vee_validate_setup () {
// Register it globally
  Vue.component('ValidationProvider', ValidationProvider);
// Install all rules https://logaretm.github.io/vee-validate/guide/rules.html#installing-all-rules
  Object.keys(rules).forEach(rule => {
    extend(rule, {
      ...rules[rule], // copies rule configuration
      message: messages[rule] // assign message
    });
  });

// Immergleiche Ermittlung des Validationstates
  Vue.prototype.$getValidationState = function({dirty, validated, valid = null}) {
    return dirty || validated ? valid : null
  }

  setInteractionMode('passive');
}
