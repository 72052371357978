const AuftragStatus = {
  offen: 'offen',
  ok: 'ok',
  Storniert: 'storniert'
}

const DateiStatus = {
  Neu: 'neu',
  Ok: 'ok',
  Fehlerhaft: 'fehlerhaft'
}

const DateiStatuus = {
  neu: 'Neu',
  ok: 'Ok',
  fehlerhaft: 'Fehlerhaft'
}

const Energiearten = {
  'strom': 'Strom',
  'gas': 'Gas'
}

const Energieart = {
  'Strom': 'strom',
  'Gas': 'gas'
}
const Kundentypen = {
  'pk': 'Privat',
  'gk': 'Gewerbe'
}
const Kundentyp = {
  'Privat': 'pk',
  'Gewerbe': 'gk'
}
const Usertyp = {
  'Firma': 'F',
  'Vertriebsleiter': 'VP',
  'Vertriebsmitarbeiter': 'VM'
}
const Dateityp = {
  'Auftrag': 'auftrag',
  'Fairberaten': 'fairberaten',
  'Sonstiges': 'sonstiges'
}
const Dateitypen = {
  'auftrag': 'Auftrag',
  'fairberaten': 'Fairberaten',
  'sonstiges': 'Sonstiges'
}
const Wechselgruende = {
  'E01': 'Umzug',
  'E02': 'Neueinzug',
  'E03': 'Lieferantenwechsel'
}
const Wechselgrund = {
  'Umzug': 'E01',
  'Neueinzug': 'E02',
  'Lieferantenwechsel': 'E03'
}
const Kuendiger = {
  'Selbstgekuendigt': 'Selbstgekündigt',
  'NeinNaechstmoeglich': 'NeinNächstmöglich',
  'NeinTermin': 'NeinTermin'
}

const Anreden = {
  Frau: 'Frau',
  Herr: 'Herr'
}

const PsoErrorCodes = {
  InvalidFormat: 'PSOF0202',
  EntityNotFound: 'PSOF0212'
}

const EmptyFormData = {
  produkt: {
    produktId: null,
    tarifId: null,
    name: null,
    produkttyp: null,
    kundentyp: null
  },
  kunde: {
    email: null,
    geburtsdatum: '1970-01-01',
    lieferadresse: {
      anrede: Anreden.Frau,
      vorname: null,
      nachname: null,
      strasse: null,
      hausnummer: null,
      hausnummerZusatz: null,
      plz: null,
      ort: null
    },
    rechnungsadresse: {
      anrede: Anreden.Frau,
      vorname: null,
      nachname: null,
      strasse: null,
      hausnummer: null,
      hausnummerZusatz: null,
      plz: null,
      ort: null
    },
    telefonnummer: {
      land: '+49',
      vorwahl: null,
      rufnummer: null
    },
    faxnummer: {
      land: '+49',
      vorwahl: null,
      rufnummer: null
    },
    bankdaten: {
      unternehmen: null,
      kontoinhaber: null,
      iban: null,
      bic: null,
      kreditinstitut: null,
      adresseKontoinhaber: {
        anrede: Anreden.Frau,
        vorname: null,
        nachname: null,
        strasse: null,
        hausnummer: null,
        hausnummerZusatz: null,
        plz: null,
        ort: null
      }
    },
    promotionErlaubtEmail: false,
    promotionErlaubtTelefonSms: false,
    promotionNewsletterErlaubt: false,
    firmenname: null,
    registergericht: null,
    handelsregisternummer: null,
    branche: null,
    marktlokation: null
  },
  zaehlernummer: null,
  verbrauch: null,
  lieferbeginn: null,
  wechselgrund: Wechselgrund.Lieferantenwechsel, // Es muss ein Wert gesetzt sein, damit es nicht leer abgeschickt werden kann.
  vorversorger: null,
  vorversorgerKundennummer: null,
  vorversorgerKuendigungsdatum: null,
  kuendiger: Kuendiger.NeinNaechstmoeglich, // Es muss ein Wert gesetzt sein, damit es nicht leer abgeschickt werden kann.
  unterschriftsdatum: null,
  unterschriftsort: null,
  widerrufsbelehrungZurKenntnis: false,
  produkttyp: Energieart.Strom,
  kundentyp: Kundentyp.Privat,
  dateien: {
    auftrag: [],
    fairberaten: [],
    sonstiges: []
  }
}

const ExampleFormData = {
  produkt: {
    produktId: 'XSH_EN12B2',
    tarifId: '396',
    name: 'Easy 24 Gas PUR',
    produkttyp: Energieart.Strom,
    kundentyp: Kundentyp.Privat,
  },
  produktId: 'XSH_EN12B2',
  kunde: {
    email: 'max@mustermann.de',
    geburtsdatum: '1970-01-01', // TODO: BeEi Date-Objekt kann in Form nicht verarbeitet werden
    lieferadresse: {
      anrede: Anreden.Herr,
      vorname: 'Max',
      nachname: 'Mustermann',
      strasse: 'Venloer Str.',
      hausnummer: '47 - 53',
      hausnummerZusatz: 'Hinterhof',
      plz: '50672',
      ort: 'Köln'
    },
    rechnungsadresse: {
      anrede: Anreden.Herr,
      vorname: 'Max',
      nachname: 'Mustermann',
      strasse: 'Venloer Str.',
      hausnummer: '47 - 53',
      hausnummerZusatz: 'Hinterhof',
      plz: '50672',
      ort: 'Köln'
    },
    telefonnummer: {
      land: '+49',
      vorwahl: '221',
      rufnummer: '569778'
    },
    faxnummer: {
      land: '+49',
      vorwahl: '221',
      rufnummer: '569778'
    },
    bankdaten: {
      unternehmen: '',
      kontoinhaber: 'Max Mustermann',
      iban: 'DE75512108001245126199',
      bic: 'BYLADEM1001',
      kreditinstitut: 'Deutsche Kredit Bank',
      adresseKontoinhaber: {
        anrede: Anreden.Herr,
        vorname: 'Max',
        nachname: 'Mustermann',
        strasse: 'Venloer Str.',
        hausnummer: '47 - 53',
        hausnummerZusatz: 'Hinterhof',
        plz: '50672',
        ort: 'Köln'
      }
    },
    promotionErlaubtEmail: false,
    promotionErlaubtTelefonSms: false,
    promotionNewsletterErlaubt: false,
    firmenname: null,
    registergericht: null,
    handelsregisternummer: null,
    branche: null,
    marktlokation: null
  },
  zaehlernummer: '123456789',
  verbrauch: 2000,
  lieferbeginn: null, // TODO: BeEi Date-Objekt kann in Form nicht verarbeitet werden
  wechselgrund: Wechselgrund.Lieferantenwechsel,
  vorversorger: '9978012000006',
  vorversorgerKundennummer: '123456789',
  vorversorgerKuendigungsdatum: '2020-01-31', // TODO: BeEi Date-Objekt kann in Form nicht verarbeitet werden
  kuendiger: Kuendiger.NeinNaechstmoeglich, // TODO: BeEi Muss in API "übersetzt" werden.
  unterschriftsdatum: '2020-02-01', // TODO: BeEi Date-Objekt kann in Form nicht verarbeitet werden,
  unterschriftsort: 'Köln',
  widerrufsbelehrungZurKenntnis: true,
  produkttyp: Energieart.Strom,
  kundentyp: Kundentyp.Privat,
  dateien: {
    auftrag: [],
    fairberaten: [],
    sonstiges: []
  }
}

export {
  Anreden,
  AuftragStatus,
  DateiStatus,
  DateiStatuus,
  Energieart,
  Energiearten,
  Kundentypen,
  Kundentyp,
  Dateityp,
  Dateitypen,
  ExampleFormData,
  EmptyFormData,
  Wechselgruende,
  Wechselgrund,
  Kuendiger,
  Usertyp,
  PsoErrorCodes
}
