class Kontaktnummer {
  land
  vorwahl
  rufnummer
  /** @param {{ land:String, vorwahl:String, rufnummer:String }} data */
  constructor(data= null) {
    if (data !== null) {
      this.land = data.land
      this.vorwahl = data.vorwahl
      this.rufnummer = data.rufnummer
    }
  }
}
export default Kontaktnummer
