import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home.vue'
import {Energiearten, Kundentyp, Usertyp} from '../../../common/constants'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/tarifrechner',
    name: 'tarifrechner',
    // route level code-splitting
    // this generates a separate chunk (tarifrechner.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tarifrechner" */ '../pages/Tarifrechner.vue')
  },
  {
    path: '/dokumente',
    name: 'dokumente',
    // route level code-splitting
    // this generates a separate chunk (tarifrechner.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dokumente" */ '../pages/Dokumente.vue'),
    beforeEnter: (to, from, next) => {
      if (router.app.$user === null) {
        window.location.href = process.env.VUE_APP_FC_URL
      } else {
        next()
      }
    }
  },
  {
    path: '/auftrag/liste',
    name: 'auftragsliste',
    // route level code-splitting
    // this generates a separate chunk (tarifrechner.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "auftragsliste" */ '../pages/Auftragsliste.vue'),
    beforeEnter: (to, from, next) => {
      if (router.app.$user === null || (router.app.$user.type !== Usertyp.Firma && router.app.$user.type !== Usertyp.Vertriebsleiter)) {
        window.location.href = process.env.VUE_APP_FC_URL
      } else {
        next()
      }
    }
  },
  {
    path: '/auftrag/neu',
    name: 'auftragerfassen',
    redirect: {
      name: 'auftragsformular',
      params: {
        produkttyp: Energiearten.Strom,
        kundentyp: Kundentyp.Privat
      }
    }
  },
  {
    path: '/auftrag/neu/:produkttyp/:kundentyp',
    name: 'auftragsformular',
    // route level code-splitting
    // this generates a separate chunk (auftragerfassen.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "auftragerfassen" */ '../pages/AuftragErfassen.vue'),
    beforeEnter: (to, from, next) => {
      if (router.app.$user === null) {
        window.location.href = process.env.VUE_APP_FC_URL
      } else {
        next()
      }
    }
  },
  {
    path: '/auftrag/erfolgreich/:auftragsnummer',
    name: 'auftragerfasst',
    // route level code-splitting
    // this generates a separate chunk (tarifrechner.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "auftragerfasst" */ '../pages/AuftragErfolgreichErfasst.vue'),
    beforeEnter: (to, from, next) => {
      if (router.app.$user === null) {
        window.location.href = process.env.VUE_APP_FC_URL
      } else {
        next()
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
