class PreisabfrageRequest {
  /** @param {{ verbrauch:Number, plz:String, kundentyp:?String, produkttyp:?String, stichtag:?Date }} requestData */
  constructor(requestData) {
    this.verbrauch = requestData.verbrauch
    this.plz = requestData.plz
    this.kundentyp = requestData.kundentyp
    this.produkttyp = requestData.produkttyp
    this.stichtag = requestData.stichtag
  }
}

export default PreisabfrageRequest
