import '../domain/Adresse'
import '../domain/Bankdaten'
import '../domain/Kontaktnummer'
class KundeRequest {
  /**
   * @param {{ email:?String, geburtsdatum:?Date, lieferadresse:Adresse, rechnungsadresse:?Adresse, telefonnummer:Kontaktnummer,
   * faxnummer:?Kontaktnummer, bankdaten:?Bankdaten, promotionErlaubtEmail:Boolean, promotionErlaubtTelefonSms:Boolean, promotionHighlightsErlaubt:Boolean
   * firmenname:?String, registergericht:?String, handelsregisternummer:?String, branche:?String }} data
   */
  constructor(data) {
    this.email = data.email
    this.geburtsdatum = data.geburtsdatum
    this.lieferadresse = data.lieferadresse
    this.rechnungsadresse = data.rechnungsadresse
    this.telefonnummer = data.telefonnummer
    this.faxnummer = data.faxnummer
    this.bankdaten = data.bankdaten
    this.promotionErlaubtEmail = data.promotionErlaubtEmail
    this.promotionErlaubtTelefonSms = data.promotionErlaubtTelefonSms
    this.promotionHighlightsErlaubt = data.promotionHighlightsErlaubt
    this.firmenname = data.firmenname
    this.registergericht = data.registergericht
    this.handelsregisternummer = data.handelsregisternummer
    this.branche = data.branche
  }
}
export default KundeRequest
