import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import tarife from '../../../common/store/tarife'
import vorversorger from '../../../common/store/vorversorger'
import branchen from '../../../common/store/branchen'

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    tarife,
    vorversorger,
    branchen
  }
})
