<template>
  <b-container>
    <b-row class="my-3">
      <b-col>
        <router-link to="/"><img alt="Fairtriebs-Champions Energie" src="../../common/assets/logo.png"></router-link>
      </b-col>
    </b-row>
    <b-row class="my-3">
      <b-col>
        <b-navbar toggleable="lg" type="light" variant="fc-gray">
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item :href="vueAppFcUrl">Zum FC-Portal</b-nav-item>
              <b-nav-item to="/" v-if="user !== null">Energie-Startseite</b-nav-item>
              <b-nav-item to="/tarifrechner">Tarifrechner</b-nav-item>
              <b-nav-item to="/dokumente" v-if="user !== null">Dokumente</b-nav-item>
              <b-nav-item to="/auftrag/liste" v-if="user !== null && (user.type === Usertyp.Firma || user.type === Usertyp.Vertriebsleiter)">Meine Aufträge</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <router-view/>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <footer class="mt-3 py-5 px-2 text-right">
          &copy; 2022 FairtriebsChampions
        </footer>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import Vue from 'vue'
  import { LayoutPlugin, NavbarPlugin } from 'bootstrap-vue'
  import {Usertyp} from '../../common/constants'

  Vue.use(LayoutPlugin)
  Vue.use(NavbarPlugin)

  export default {
    name: 'App',
    data() {
      return {
        Usertyp: Usertyp,
        vueAppFcUrl: process.env.VUE_APP_FC_URL
      }
    },
    computed: {
      user() {
        return this.$user
      }
    }
  }
</script>

<style lang="scss">

</style>
