import Vue from 'vue'

let rootElementId = 'app'
let rootElement = document.getElementById(rootElementId)
let config = JSON.parse(rootElement.getAttribute('data-config'))
Vue.prototype.$config = config

import Portal from './Portal.vue'
import router from './router'
import store from './store'
import axios_setup from '../../common/plugins/axios'

import './styles/app.scss';

Vue.config.productionTip = false

axios_setup(config['t'])

try {
  let jwt = config.t.split('.')
  let payload_str = atob(jwt[1])
  let payload = JSON.parse(payload_str)
  Vue.prototype.$user = {
    id: payload.psopay.userId,
    anrede: payload.psopay.anrede,
    vorname: payload.psopay.vorname,
    nachname: payload.psopay.nachname,
    type: payload.psopay.userType
  }
} catch (e) {
  Vue.prototype.$user = null
}

import vee_validate_setup from '../../common/plugins/vee-validate'
vee_validate_setup()

new Vue({
  router,
  store,
  render: h => h(Portal)
}).$mount('#' + rootElementId)
